import React from 'react';
import { styled, Typography, Dialog, IconButton, DialogContent, Box } from '@mui/material';
import { closeIcon } from '../assets';

interface Props {
    onDisconnect: () => void,
    onClose: () => void,
    isOpen: boolean,
    name: string,
    nameColor: string,
    imgURL: string
}

const DisconnectModal: React.FC<Props> = ({ onDisconnect, onClose, isOpen, name, nameColor, imgURL }) => {

    return (
        <StyledDialog onClose={() => onClose()} aria-labelledby="customized-dialog-title" open={isOpen} data-testid="disconnectModal">
            <IconBtn aria-label="close" data-testid='btnCloseModal' onClick={() => onClose()} >
                <img src={closeIcon} alt="close_icon" />
            </IconBtn>
            <DialogContent dividers>
                <ModalHeading>{"Disconnect account?"}</ModalHeading>
                <ModalContent>{"Are you sure you want to disconnect your account?"}</ModalContent>
                <BoxModal>
                    <img src={imgURL} className="account_img" />
                    <Typography className='account_text' style={{color: nameColor}}>{name}</Typography>
                </BoxModal>
                <ButtonBox>
                    <CancelButton onClick={() => onClose()} data-testid="cancel-btn">Cancel</CancelButton>
                    <DisConnectModalBtn onClick={() => onDisconnect()} data-testid="disconnect-btn">Disconnect</DisConnectModalBtn>
                </ButtonBox>
            </DialogContent>
        </StyledDialog>
    );

}

export default DisconnectModal;

const StyledDialog = styled(Dialog)({
    textAlign: "center",
    "& .MuiDialog-paper": {
        width: '529px',
        borderRadius: "24px",
        boxSizing: 'border-box',
    },
    '& .MuiDialogContent-root': {
        padding: '40px 40px',
    },
});

const IconBtn = styled(IconButton)({
    position: 'absolute',
    right: 16,
    top: 16,
});

const ModalHeading = styled(Box)({
    fontFamily: 'Open Sans',
    fontSize: '20px',
    fontWeight: 600,
    color: "#4D4D4D",
})

const ModalContent = styled(Typography)({
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 400,
    color: "#4D4D4D",
    marginTop: "16px"
})

const BoxModal = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    marginTop: "16px",
    "& .account_text": {
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: 400,
        color: '#7153B8'
    },
    "& .facebook_text_model": {
        fontFamily: 'Open Sans',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '24.51px',
        color: "#1977F3"
    }
})

const ButtonBox = styled(Box)({
    display: "flex",
    marginTop: "35px",
    width: "100%",
    justifyContent: "space-evenly",
    gap: "20px"
})

const CancelButton = styled(Box)({
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 600,
    backgroundColor: '#000000',
    color: "#FFFFFF",
    padding: "10px 16px",
    borderRadius: "8px",
    cursor: "pointer",
    flex: "fit-content"
})

const DisConnectModalBtn = styled(Box)({
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 700,
    color: "#000000",
    padding: "10px",
    borderRadius: "8px",
    cursor: "pointer",
    border: "1px solid #A8A8A8",
    flex: "fit-content"
})